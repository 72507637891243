import { useEffect, useState } from 'react';
import './App.css';
import { GameOfLife } from './cgol';
import Logo from './images/mobian-icon-blue.png';
import PCB from './images/pcb-outline.png';

function App() {
  return (
    <div style={{ backgroundImage: 'linear-gradient(335deg, #010f17, #2D383A, #010f17)', height: '100%', width: '100%', position: 'fixed' }}>
      <div style={{ zIndex: 1, height: '100%', width: '100%', position: 'fixed', backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0) 100%)'}}></div>
      <GameOfLife TileSize={4} TickRate={2} Alpha={0.03} InitDensity={0.3} style={{ zIndex: 0, filter: 'blur(5px)' }}/>
      <div className="App" style={{ zIndex: 2, height: '100%', width: '100%', position: 'relative' }}>
        <section style={{ position: 'fixed', top: '0px', right: '0px', display: 'block', margin: '20px', color: '#fff', fontSize: '12px' }}>
          <a href='mailto: tx+www@mobian.io' style={{ color: '#fff' }}>Contact Us</a>
        </section>
        <img src={Logo} className="Logo" />
        <div className="App-header">
          <div className="Container">
            <h1>Mobian Dynamics</h1>
            Building an autonomous future through Avionics & Unmanned systems
          </div>
          <img src={PCB} className="Hero-image"></img>
        </div>
        <section style={{ color: 'rgba(255,255,255, 0.5)', margin: '30px 50px', textAlign: 'center' }}>
          We're a small team based in Melbourne, Australia. The future is <strong style={{ color: 'white' }}>autonomy</strong> and we are in the process of building that out.<br />
          There's not a lot of public content yet but stay tuned.
        </section>
        <section style={{ position: 'fixed', bottom: '0px', display: 'block', margin: '20px', color: '#fff', fontSize: '11px' }}>
          &copy; {new Date().getFullYear()} Mobian Dynamics.
        </section>
      </div>
    </div>
  );
}

export default App;
