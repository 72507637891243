import { FC, forwardRef, useEffect, useRef, useState } from "react"

const BaseCanvas: FC<any> = forwardRef<any, HTMLCanvasElement>(({ width, height }, ref) => {
    return(
        <div>
            <canvas ref={ref} id="cgol" width={width} height={height} style={{ width: '100%', height: '100%', position: 'absolute' }}></canvas>
        </div>
    )
});

const prepareBoardModel = (X: number, Y: number): boolean[][] => {
    const b = [];
    for(let i=0;i < X;i++) {
        const row = [];
        for(let j=0;j < Y;j++) {
            row.push(false);
        }
        b.push(row);
    }
    return b;
}

const isAlive = (x: number, y: number, board: boolean[][]): number => {
    const TILES_X = board.length;
    if (!board.length) {
        return 0;
    }
    const TILES_Y = board[0].length;
    if (x < 0 || x >= TILES_X || y < 0 || y >= TILES_Y) {
        return 0;
    }
    return board[x][y] ? 1 : 0;
}

const neighboursCount = (x: number, y: number, board: boolean[][]): number => {
    let count = 0;
    for(let i of [-1, 0, 1]) {
        for(let j of [-1, 0, 1]) {
            if (!(i === 0 && j === 0)) {
                count += isAlive(x+i, y+j, board);
            }
        }
    }
    return count;
}

const getArcRadius = (TileSize: number) => TileSize/2 * 0.5;

const drawBoard = (board: boolean[][], ctx: CanvasRenderingContext2D, TILES_X: number, TILES_Y: number, TileSize: number) => {
    ctx.restore();
    for(let i=0;i<TILES_X;i++) {
        for(let j=0;j<TILES_Y;j++) {
            if (!isAlive(i, j, board)) {
                continue;
            }
            ctx.beginPath();
            ctx.arc(i * TileSize - (TileSize/2), j * TileSize - (TileSize/2), getArcRadius(TileSize), 0, 2 * Math.PI, false)
            ctx.fill();
        }
    }
}

const computeNextGeneration = (board: boolean[][], TILES_X: number, TILES_Y: number) => {
    for(let i=0;i<TILES_X;i++) {
        for(let j=0;j<TILES_Y;j++) {
            const count = neighboursCount(i, j, board);
            if (!isAlive(i, j, board)) {
                if (count === 3) {
                    board[i][j] = true;
                }
            } else {
                
                if (count == 2 || count == 3) {
                    board[i][j] = false;
                }
            }
        }
    }
    return board;
}

const clear = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
    ctx.clearRect(0, 0, width, height);
}
const drawAll = (ctx: CanvasRenderingContext2D, board: boolean[][], width: number, height: number, TILES_X: number, TILES_Y: number, TileSize: number) => {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempCtx = tempCanvas.getContext('2d')!;
    tempCtx.fillStyle = ctx.fillStyle;
    drawBoard(board, tempCtx, TILES_X, TILES_Y, TileSize);
    
    clear(ctx, width, height);
    ctx.drawImage(tempCanvas, 0, 0);
}

const nextGen = ({ ctx, board, width, height, TILES_X, TILES_Y, TileSize }: { ctx: CanvasRenderingContext2D, board: boolean[][], width: number, height: number, TILES_X: number, TILES_Y: number, TileSize: number }) => {
    board = computeNextGeneration(board, TILES_X, TILES_Y);
    drawAll(ctx, board, width, height, TILES_X, TILES_Y, TileSize);
}

const init = (board: boolean[][], density: number): boolean[][] => {
    for (let x = 0; x < board.length; x++) {
        for (let y = 0; y < board[x].length; y++) {
            if (y % Math.round(50 * Math.random()/density) === 0 || x % Math.round(50 * Math.random()/(density)) === 0) {
                board[x][y] = true;
            }
            
        }
    }
    return board;
}


export const config = { lock: false, board: [] as boolean[][] };
export const GameOfLife: FC<any> = ({ TileSize = 20, TickRate = 1, Alpha = 0.005, InitDensity = 0.1, RGB = [255, 255, 255] }) => {
    const ref = useRef<HTMLCanvasElement>();
    const [tick, setTick] = useState(0);
    const [int, setInt] = useState<any>();
    const [dim, setDim] = useState({ width: window.innerWidth, height: window.innerHeight });
    useEffect(() => {
        window.addEventListener('resize', () => {
            config.board = init(prepareBoardModel(TILES_X, TILES_Y), InitDensity);
            setDim({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        })
    }, []);

    const TILES_X = Math.ceil(dim.width / TileSize);
    const TILES_Y = Math.ceil(dim.height / TileSize);
    
    if(ref.current) {
        const canvas = ref.current;
        const ctx = canvas.getContext("2d")!;

        const getConfig = () => ({
            ctx,
            board: config.board,
            width: dim.width,
            height: dim.height,
            TILES_X,
            TILES_Y,
            TileSize
        });

        const styleContext = () => {
            const grd = ctx.createRadialGradient(0, 0, 5, 0, 0, getArcRadius(TileSize));
            grd.addColorStop(0, `rgba(${RGB[0]}, ${RGB[1]}, ${RGB[2]}, 1)`);
		    grd.addColorStop(1, `rgba(${RGB[0]}, ${RGB[1]}, ${RGB[2]}, 0)`);
            ctx.fillStyle = 'white';
            ctx.globalAlpha = Alpha;
        }
        
        if(!int && config.lock === false) {
            config.lock = true;
            config.board = init(prepareBoardModel(TILES_X, TILES_Y), InitDensity);
            const i = setInterval(() => {
                styleContext();
                setTick(tick + 1);
                nextGen(getConfig());
            }, Math.ceil(1000/TickRate));
            setInt(i);
        }
    }

    useEffect(() => {}, [TileSize, TickRate, Alpha, InitDensity]);
    
    useEffect(() => {
        if(int) {
            return () => clearInterval(int);
        }
        setTick(tick + 1);
    }, [])
        
    return <BaseCanvas ref={ref} width={dim.width} height={dim.height} />
}
